import { IImportResult, ILoginResult, IUser } from "../models";
import _ from 'lodash';
const { NODE_ENV } = process.env;

let baseUrl = NODE_ENV === 'production' ? 'https://kdotrucenrollmentapi.azurewebsites.net/api/' : 'http://localhost:7071/api/';
let apiKey:string = '';

export function setBaseUrl(url: string) {
    baseUrl = url;
}

export function setApiKey(key:string) {
    apiKey = key;
}

export const loginAdmin = createEndpointWithResponse<ILoginResult>('loginadmin');
export const getUsers = createEndpointWithResponse<IUser[]>('users');
export const getUser = createEndpointWithResponse<string, IUser>('user/{0}');
export const findUser = createEndpointWithResponse<string, IUser>('finduser/{0}');
export const findIpsosUser = createEndpointWithResponse<string, IUser>('findipsosuser/{0}');
export const updateUser = createEndpointWithPayload<string, IUser, IUser>('user/{0}', 'POST');
export const addUsers = createEndpointWithOnlyPayload<IUser[], IImportResult>('users', 'POST');

function createEndpointWithResponse<TResponse>(endpoint: string, method?: "GET" | "POST"): () => Promise<TResponse>;
function createEndpointWithResponse<TRequest, TResponse>(endpoint: string, method?: "GET" | "POST"): (request: TRequest) => Promise<TResponse>;
function createEndpointWithResponse<TRequest, TResponse>(endpoint: string, method?: "GET" | "POST"): (request: TRequest) => Promise<TResponse> {
    return async (request?: TRequest): Promise<TResponse> => {
        return await executeRequest(endpoint, method || 'GET', request);
    };
}

function createEndpointWithOnlyPayload<TPayload, TResponse>(endpoint: string, method?: "GET" | "POST"): (payload: TPayload) => Promise<TResponse> {
    return async (payload?: TPayload): Promise<TResponse> => {
        return await executeRequest(endpoint, method || 'GET', null, payload);
    };
}

function createEndpointWithPayload<TRequest, TResponse>(endpoint: string, method?: "GET" | "POST"): (request: TRequest) => Promise<TResponse>;
function createEndpointWithPayload<TRequest, TPayload, TResponse>(endpoint: string, method?: "GET" | "POST"): (request: TRequest, payload: TPayload) => Promise<TResponse>;
function createEndpointWithPayload<TRequest, TPayload, TResponse>(endpoint: string, method?: "GET" | "POST"): (request: TRequest, payload: TPayload) => Promise<TResponse> {
    return async (request?: TRequest, payload?: TPayload): Promise<TResponse> => {
        return await executeRequest(endpoint, method || 'GET', request, payload);
    };
}

function formatString(formatString: string, ...replacements: string[]): string {
    const args = arguments;
    return formatString.replace(/{(\d+)}/g, function(match, value) {
      const index = Number(value) + 1;
      console.log(index);
      return typeof args[index] != 'undefined'
        ? args[index]
        : match
      ;
    });
}

function fixKeys(obj: any) {
    if (_.isArray(obj)) {
        obj.forEach((item) => {
            fixKeys(item);
        });
    }
    else {
        Object.keys(obj).forEach((key) => {
            if (key === 'RowKey') {
                obj.rowKey = obj[key];
                delete obj[key];
            } else if (key === 'PartitionKey') {
                obj.partitionKey = obj[key];
                delete obj[key];
            }
        });
    }
}

const executeRequest = async (url: string, method: "GET" | "POST", urlParameters: any, payload: any = null) => {
    const formattedUrl = formatString(url, urlParameters);
    let fullUrl = baseUrl + formattedUrl;

    console.log(`Calling ${fullUrl}`);
    return fetch(fullUrl, {
        method,
        mode: 'cors',
        headers:{
            'x-functions-key':apiKey,
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: payload && JSON.stringify(payload, (k, v) => v === undefined ? null : v)
    })
        .then(async response => {
            if (response.status < 200 || response.status >= 300) {
                const error = Error(`${response.status}: [${response.statusText}]`);
                error.name = 'ServiceError';
                console.log(error);
                throw error;
            }

            const contentType = response.headers.get("content-type");
            if (!contentType || contentType.indexOf('/json') === -1) {
                const error = Error('Service returned an unexpected content-type: ' + contentType);
                console.log(error);
                throw error;
            }

            return response.json();
        })
        .then(responseJson => {
            console.log(responseJson);
            if (!Array.isArray(responseJson) && Object.keys(responseJson).length === 0) {
                return null;
            }
            fixKeys(responseJson);
            return responseJson;
        });
}
