import { GridColumns, GridValueGetterParams, GridValueSetterParams, ValueOptions } from "@mui/x-data-grid";
import { IUser } from "../models";
import _ from 'lodash';
import { renderBooleanCell } from "./GridBooleanCell";
import { renderEditBooleanCell } from "./GridEditBooleanCell";

const singleSelectGetter = (params: GridValueGetterParams<any, any>): IUser => {
    return _.isNil(params.row[params.field]) ? '' : params.row[params.field];
}

const singleSelectSetter = (field: string, params: GridValueSetterParams<any, any>): IUser => {
    const copy = {...params.row};
    copy[field] = params.value === '' ? undefined : params.value;
    return copy;
}

const booleanProps = {
    type: 'boolean',
    renderCell: renderBooleanCell,
    renderEditCell: renderEditBooleanCell
}

const unsetValue: ValueOptions = { value: '', label: '[unset]'};
export const columns: GridColumns<IUser> & { simField?: boolean } = [
    { field: 'id', headerName: 'ID', width: 300 },
    { field: 'ipsos_id', headerName: 'Ipsos_ID', editable: true, width: 70 },
    { field: 'first_name', headerName: 'First_Name', editable: true, width: 120 },
    { field: 'last_name', headerName: 'Last_Name', editable: true, width: 160 },
    { field: 'state', headerName: 'State', editable: true, width: 60 },
    { field: 'county', headerName: 'County', editable: true, width: 160 },
    { field: 'email', headerName: 'Email', editable: true, width: 220 },
    { field: 'terms_accepted', headerName: 'TermsAccepted', ...booleanProps, editable: true, width: 160 },
    { field: 'veh_is_hd', headerName: 'Veh_Heavy_Duty', ...booleanProps, editable: true, width: 160 },
    { field: 'vin', headerName: 'VIN', editable: true, width: 170 },
    { field: 'vehyear', headerName: 'VehYear', type: 'number', editable: true, width: 70, simField: true },
    { field: 'vehmake', headerName: 'VehMake', editable: true, width: 90, simField: true },
    { field: 'vehmodel', headerName: 'VehMod', editable: true, width: 90, simField: true },
    { field: 'vehtrim', headerName: 'VehTrm', editable: true, width: 90, simField: true },
    { field: 'vehcustom', headerName: 'VehCustom', editable: true, width: 180, simField: true },
    { field: 'veh_weight', headerName: 'VehWeight', type: 'singleSelect', editable: true, width: 120, valueOptions: [
        unsetValue,
        'Class 3: 10,001 - 14,000 lbs',
        'Class 4: 14,001 - 16,000 lbs',
        'Class 5: 16,001 - 19,000 lbs',
        'Class 6: 19,501 - 26,000 lbs',
        'Class 7: 26,001 - 33,000 lbs',
        'Class 8: >33,001 lbs'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('veh_weight', params) },
    { field: 'veh_epa_atvtype', headerName: 'Veh_Epa_Atvtype', type: 'singleSelect', editable: true, width: 140, valueOptions: [
        unsetValue,
        'Bifuel (CNG)',
         'Bifuel (LPG)',
         'CNG', 
         'Diesel',
         'EV',
         'FFV', 
         'Hybrid', 
         'Plug-in',
         'Hybrid'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('veh_weight', params) },
    { field: 'telematics', headerName: 'Telematics', ...booleanProps, editable: true, width: 90, simField: true },
    { field: 'smart_phone', headerName: 'SmartPhone', ...booleanProps, editable: true, width: 110, simField: true },
    { field: 'mpg', headerName: 'MPG', type: 'number', editable: true, width: 80, simField: true },
    { field: 'veh_override', headerName: 'VEH_Override', ...booleanProps, editable: true, width: 110, simField: true },
    { field: 'mi_est', headerName: 'Mi_Est', type: 'number', editable: true, width: 110, simField: true },
    { field: 'exempt_mi', headerName: 'Exempt_Mi', type: 'number', editable: true, width: 110, simField: true },
    { field: 'veh_mi', headerName: 'VehicleMiles', type: 'number', editable: true, width: 140, simField: true },
    { field: 'sim_status', headerName: 'SimStatus', type: 'string', editable: false, width: 120, simField: true  },
    { field: 'mro_selection', headerName: 'MroSelection', type: 'singleSelect', editable: true, width: 120, valueOptions: [
        unsetValue,
        'Manual',
        'Photo',
        'Smartapp',
        'Telematics'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('mro_selection', params) },
    { field: 'deleted', headerName: 'Deleted', ...booleanProps, editable: true, width: 80 },
  ];

export const columnsByField = new Map(columns.map((x) => [x.field, x]));
export const columnsByLabel = new Map(columns.map((x) => [x.headerName || '', x]));


