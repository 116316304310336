import React from 'react';
import './App.css';
import Users from './components/Users';
import Login from './components/Login';

function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);

  return (
    <div className="App">
      <header className="App-header">
        {isLoggedIn ?
            <Users/>
        :
            <Login loggedIn={() => setIsLoggedIn(true)}/>
        }
      </header>
    </div>
  );
}

export default App;
