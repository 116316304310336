import { Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import * as Server from '../server';
import BasicModal from './BasicModal';

interface ILoginProps {
    loggedIn: () => void
}

export default function Login(props: ILoginProps) {
    const [key, setKey] = useState<string>('');
    const [showLoginFailure, setShowLoginFailure] = useState<boolean>(false);

    const login = React.useCallback(async () => {
        Server.setApiKey(key);
        Server.loginAdmin().catch(reason => {
            setShowLoginFailure(true);
        }).then(res => {
            if (res && res.success) {
                props.loggedIn();
            }
            else {
                setShowLoginFailure(true);
            }
        });
    }, [key, props]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const queryKey = queryParams.get('key');
        if (queryKey) {
            if (queryKey !== key) {
                setKey(queryKey);
            }
            else {
                login();
            }
        }
    }, [login]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && key.length > 0) {
            login();
        }
    };

    return (
        <>
        <div>
            <span>Key:</span>
            <input type='text' value={key} onChange={(e) => setKey(e.target.value)} onKeyUp={handleKeyUp}/>
            <Button onClick={login}>Login</Button>
        </div>
        <BasicModal open={showLoginFailure} onClose={() => setShowLoginFailure(false)}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Login failed
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                The supplied key is not valid.
            </Typography>
        </BasicModal>
        </>
      );
}
